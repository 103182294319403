<template>
  <div v-if="hasReportAccess && canAddReport" class="reports-repository-container px-4">
    <div class="reports-repo-header">
      <div class="page-title">Add Single Report</div>
    </div>
  </div>
  <div v-else class="reports-not-found-container">
    "Please contact the administrator for access to this feature."
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { metaTitle } from '@/constant/data'
export default {
  name: 'AddSingleReport',
  metaInfo () {
    return {
      title: this.pageTitle
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState('user', ['userDetails']),
    ...mapState('common', ['currentModule', 'active_page']),
    pageTitle () {
      return metaTitle[this.active_page]
    },
    hasReportAccess () {
      const reportModule = this.userDetails.modules.find(module => module.module_name === 'reports-repository')
      if (reportModule.moduleProperties && reportModule.moduleProperties.subTypes && reportModule.moduleProperties.subTypes.length > 0) {
        return true
      }
      return false
    },
    canAddReport () {
      const permission = this.userDetails.permissions.find(
        (perm) => perm.path === 'add-report'
      )

      // If the permission for the given feature is not found, the user does not have permission for that feature
      if (!permission) {
        return false
      }

      // Check if the user has permission for the specified action (read, write, update, delete)
      return permission['write']
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./reportsRepository.scss";
.reports-repository-container {
  .reports-repo-header {
      height: 54px;
  }
}
</style>
